// @font-face {
//   font-family: 'Roboto';
//   src: local('Roboto'), local('Roboto-Regular'),
//     url('../fonts/Roboto-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: local('Roboto'), local('Roboto-Medium'), url('../fonts/Roboto-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Great Vibes';
  src: local('Roboto'), local('Great Vibes'),
    url('../fonts/GreatVibes-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: cursive;
  font-display: swap;
}

.feedback-container {
  border: grey 1px solid;
  width: 500px;
  padding: 40px;
}

.feedback-input {
  display: block;
  margin: 20px;
  height: 40px;
  border-radius: 40px;
}

.feedback-button {
  height: 50px;
  width: 180px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background: $accent-color;
  border-color: transparent;
  border-radius: 15px;
  color: $white-text-color;
  font-size: 20px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 5px 5px;
    color: $text-color;
    cursor: pointer;
    border-color: 3px green solid;
  }
}

.video-list {
  list-style: none;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.video {
  width: 500px;
  margin: 20px;
}

.video-title {
  width: 480px;
  text-align: center;
}

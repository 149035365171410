.page-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  color: $text-color;
}

.side-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  position: sticky;
  @media screen and (max-width: 767.8px) {
    display: none;
  }

  @media screen and (min-width: 768px) {
    padding-left: 5px;
    padding-top: 5px;
    top: 85px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 25px;
    padding-top: 10px;
    top: 85px;
  }
  @media screen and (min-width: 1440px) {
    padding-top: 20px;
    padding-left: 20px;
  }
}

.side-menu-link {
  cursor: pointer;
  background: $text-color;
  list-style: none;
  @media screen and (min-width: 768px) {
    width: 200px;
    padding: 5px;
    font-size: 15px;
  }
  @media screen and (min-width: 1024px) {
    width: 250px;
    padding: 10px;
    font-size: 15px;
  }
  @media screen and (min-width: 1440px) {
    width: 350px;
    font-size: 20px;
    padding: 15px;
  }

  color: $white-text-color;
  margin-bottom: 5px;
  text-align: left;

  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
}
.side-menu-link-active {
  background-color: rgba(0, 0, 0, 0.4);
}

.content {
  @media screen and (max-width: 767.8px) {
    // display: none;
    font-size: 12px;
    padding: 10px;
  }
  display: block;
  font-size: 20px;
  padding: 25px;
  text-align: justify;
}

h2,
h3 {
  text-align: center;
  padding: 15px;
}

li {
  margin-bottom: 20px;
  margin-left: 20px;
  @media screen and (max-width: 767.8px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 768px) {
    margin-left: 10px;
  }
  @media screen and (min-width: 1440px) {
    margin-left: 20px;
  }
}

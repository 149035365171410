.mobile__backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.mobile__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mobile__modal {
  @media screen and (max-width: 767.8px) {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $white-text-color;
    height: 100%;
    overflow-y: scroll;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
.mobile__modal--btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: $hover-color;
  }
}

.mobile__modal--close-svg {
  fill: currentColor;
}

.mobile-menu-list {
  margin-top: 40px;
  margin-right: 40px;
}

.mobile-menu-link {
  background: $text-color;
  color: $white-text-color;
  margin-bottom: 10px;
  text-align: left;
  padding: 10px;
  border-radius: 15px;
}

.mobile-menu-button {
  background-color: transparent;
  border: none;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.footer {
  width: 100%;
  margin: 0 auto;
  min-height: 150px;
  background: $text-color;
  margin-top: 10px;
}
.footer-logo {
  position: absolute;
  left: calc(50% - 70px);
  height: 80px;
  width: 140px;
  margin: 15px 0;
  border-radius: 50%;
}
.footer-title {
  @media screen and (max-width: 768px) {
    font-size: 20px;
    position: absolute;
    margin-top: 90px;
    left: calc(50% - 150px);
  }
  position: absolute;
  margin-top: 90px;
  left: calc(50% - 200px);
  font-family: 'Great Vibes', cursive;
  font-size: 30px;
  color: $white-text-color;
}

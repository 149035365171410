.cv-table {
  box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 3px 3px;
  border-collapse: collapse;
  &th,
  td {
    padding: 10px;
    border: 1px solid #2a2a2a;
  }
  margin-bottom: 55px;
}

.about-me-logo {
  height: 100px;
}

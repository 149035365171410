h3 {
  text-align: left;
}

.appointment__backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.success-status-on-form {
  color: green;
  font-size: 20px;
  margin-top: 20px;
}

.error-status-on-form {
  color: red;
  font-size: 20px;
  margin-top: 20px;
}

.appointment__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.appointment__backdrop.is-hidden .appointment__modal {
  transform: translate(-50%, -50%) scale(1.1);
}

.dark-theme .appointment__modal--btn-close {
  color: $text-color;
}
.appointment__ok {
  font-size: 15px;
  color: green;
  text-transform: uppercase;
  margin-top: 15px;
}

.appointment__modal--btn-close {
  position: absolute;
  top: 8px;
  right: 8px;

  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: $hover-color;
  }
}

.appointment__modal--close-svg {
  fill: currentColor;
}

.appointment__modal {
  @media screen and (max-width: 767.8px) {
    width: 300px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $white-text-color;
  height: 90%;
  overflow-y: scroll;

  transform: translate(-50%, -50%) scale(1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.backdrop.is-hidden .appointment__modal {
  transform: translate(-50%, -50%) scale(1.1);
}

.dark-theme .appointment__modal {
  background-color: #333333;
}

.dark-theme .appointment__box {
  background-color: #333333;
}

.input__box {
  margin-top: 100px;
  @media screen and (max-width: 767.8px) {
    margin-top: 10px;
  }
}

.input__field {
  @media screen and (max-width: 767.8px) {
    width: 200px;
  }
  border-radius: 15px;
  outline: none;
  width: 300px;
  height: 40px;
  text-indent: 5px;
  font-size: 20px;
  border: 1px solid rgb(216, 213, 213);
}

.message {
  @media screen and (max-width: 767.8px) {
    width: 200px;
  }
  width: 500px;
  height: 100px;
  resize: none;
  padding: 10px;
}

.button__field {
  margin-top: 50px;
}

.appointment_modal--btn-appointment {
  width: 180px;
  height: 50px;
  border: 1px solid rgb(216, 213, 213);
  border-radius: 15px;
  background-color: transparent;
  color: $text-color;
  font-size: 15px;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &:hover,
  &:focus {
    border: 1px solid #ff6b01;
    background: #ff6b01;
    box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 5px 5px;

    transition-property: background-color;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
  }
}

.appointment {
  @media screen and (max-width: 767.8px) {
    width: 290px;
    margin: 5px;
  }
  @media screen and (min-width: 767.9px) {
    width: 600px;
  }
  padding: 15px;
  margin: 50px;

  width: 700px;
  color: $text-color;
}

.appointment__title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.17;
  margin-bottom: 20px;
  text-align: center;
}

.input__name {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

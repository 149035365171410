.wellness-decoration-picture {
  @media screen and (max-width: 767.8px) {
    width: 300px;
  }
  display: block;
  margin-top: 20px;
  width: 300px;
}

.picture-container {
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}

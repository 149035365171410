.oorkaarsen-picture {
  width: 300px;
  height: 300px;
  @media screen and (max-width: 767.8px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.oorkaarsen-picture-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media screen and (max-width: 767.8px) {
    flex-wrap: wrap;
  }
}

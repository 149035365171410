.contact-image {
  width: 650px;
  display: block;
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 5px 5px;
}
.map-frame {
  @media screen and (max-width: 767.8px) {
    width: 300px;
  }
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 5px 5px;
}

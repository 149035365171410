.contact-info {
  @media screen and (max-width: 767.8px) {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
  list-style: none;
  color: grey;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 3px 3px;
  width: 250px;
  border-radius: 15px;
  padding: 15px;
  border: rgba(0, 0, 0, 0.1) solid 1px;
  margin: 40px 0;
  font-size: 10px;
}
.contact-item {
  margin-top: 15px;
}
.gift-page {
  color: $text-color;
}
.contact-link {
  color: $text-color;
  display: inline-block;
  margin-top: 15px;
}
.contact-link-first {
  color: $text-color;
  display: inline;
}
.gift-title {
  //   text-align: left;
  color: $text-color;
}
.gift-image {
  @media screen and (max-width: 767.8px) {
    width: 300px;
  }
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: block;
  width: 450px;
}
.gift-container {
  @media screen and (max-width: 767.8px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 450px;
  align-items: center;
}
.buy-now-button {
  height: 50px;
  width: 180px;
  margin-top: 40px;
  margin-bottom: 40px;

  background: $accent-color;

  border-color: transparent;
  border-radius: 15px;
  color: $white-text-color;
  font-size: 20px;
}

.buy-now-button:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 5px 5px;
  color: $text-color;
  cursor: pointer;
  border-color: 3px green solid;
}

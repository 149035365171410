$font-family: Roboto, sans-serif;
$logo-font: 'Great Vibes', cursive;
$main-shadow: rgba(0, 0, 0, 0.4) 10px 10px 5px 5px;
$font-size: 12px;
$font-weight: 500;
$font-style: normal;
$line-height: calc(16 / 12);
$text-color: rgba(0, 0, 0, 0.8);
$container-background: rgba(0, 0, 0, 0.6);
$white-text-color: #ffffff;
$accent-color: #ff6b08;
$header-gradient: rgba(0, 0, 0, 0.5);
$gradient-background: rgba(47, 48, 58, 0.4);
$background-alternative-color: #f7f7f7;
$footer-text-color: #545454;
$primary-color: #333333;
$hover-color: #ff6b01;
$film-icon-color: #818181;
$footer-color-switch: #222;
$theme-switch-color: #d3d3d4;
$theme-swtch-toogle: #2196f3;
$transition-duration: 250ms;
$timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$modal-description-color: #8c8c8c;
$news-link-color: #ff4500;

a {
  text-decoration: none;
  color: $white-text-color;
}

ul {
  padding: 0;
}

.header {
  @media screen and (max-width: 1023.8px) {
    background-size: cover;
    min-height: 180px;
  }
  background-image: url('../images/header-bg.jpeg');

  background-size: contain;
  width: 100%;
  margin: 0 auto;
  min-height: 380px;

  background-repeat: no-repeat;
  position: relative;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: $text-color;
  @media screen and (max-width: 767.8px) {
    min-height: 60px;
    justify-content: flex-start;
    :not(:nth-child(9n + 1)) {
      display: none;
    }
    justify-content: space-between;
    padding-right: 20px;
  }
  @media screen and (min-width: 768px) {
    :not(:first-child) {
      margin-left: 15px;
    }
    font-size: 12px;
    padding-top: 30px;
    padding-bottom: 20px;
    height: 80px;
    width: 100%;
    color: $white-text-color;
    position: fixed;
    z-index: 99;
  }
  @media screen and (min-width: 1024px) {
    :not(:first-child) {
      margin-left: 15px;
    }
    padding-left: 20px;
    font-size: 15px;
    padding-top: 30px;
    padding-bottom: 20px;
    height: 80px;
    width: 100%;
    color: $white-text-color;
    position: fixed;
    z-index: 99;
  }
  @media screen and (min-width: 1440px) {
    :not(:first-child) {
      margin-left: 30px;
    }
    padding-left: 20px;
    font-size: 20px;
  }
}

.nav-link:hover {
  color: $hover-color;
}

.nav-link {
  @media screen and (max-width: 767.8px) {
    :last-child {
      margin-right: 20px;
    }
  }
  position: relative;
  padding: 0px;
}

.current::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -25%;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: $hover-color;
}

.main-nav {
  text-align: center;
  @media screen and (min-width: 768px) {
    margin-right: 0px;
  }
}

.header-button {
  background: $accent-color;
  margin-right: 0;
  display: block;
  @media screen and (max-width: 767.9px) {
    font-size: 15px;
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    right: 10px;
    bottom: 20px;
    height: 40px;
    width: 110px;
    font-size: 12px;
    margin-right: 20px;
  }
  @media screen and (min-width: 1024px) {
    position: absolute;
    right: 90px;
    bottom: 20px;
    height: 40px;
    width: 110px;
  }
  @media screen and (min-width: 1440px) {
    position: absolute;
    right: 190px;
    bottom: 20px;
    height: 50px;
    width: 180px;
  }
  @media screen and (min-width: 1500px) {
    position: absolute;
    right: 190px;
  }
  border-color: transparent;
  border-radius: 50px;
  color: $white-text-color;
  font-size: 20px;
}

.header-button-is-animated {
  @media screen and (min-width: 1440px) {
    animation-name: rightToLeft;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    @keyframes rightToLeft {
      0% {
        transform: translatex(600px);
      }

      100% {
        transform: translatex(0);
      }
    }
  }
  @media screen and (min-width: 1024px) {
    animation-name: rightToLeft;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    @keyframes rightToLeft {
      0% {
        transform: translatex(600px);
      }

      100% {
        transform: translatex(0);
      }
    }
  }
}

.header-button:hover {
  box-shadow: $main-shadow;
  color: $text-color;
  cursor: pointer;
}

.logo {
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 768px) {
    position: absolute;
    bottom: 150px;
    right: 20px;
    font-size: 30px;
  }

  @media screen and (min-width: 1024px) {
    position: absolute;
    bottom: 70px;
    right: 20px;
  }

  @media screen and (min-width: 1500px) {
    right: 200px;
    font-size: 60px;
  }
  font-family: $logo-font;
  font-size: 40px;
  color: $text-color;
  text-align: center;
}

.logo-is-animated {
  animation-name: move;

  animation-duration: 1.5s;
  animation-timing-function: linear;

  @keyframes move {
    0% {
      transform: translatey(40px);
      color: white;
    }

    100% {
      transform: translatey(0);
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.alisa {
  display: block;
  font-size: 50px;
  font-family: $logo-font;
  text-align: center;
}

.facebook-link {
  background-color: gray;
  border-radius: 50%;
  padding: 3px;
  width: 30px;
  height: 30px;
  fill: $white-text-color;
  &:hover {
    fill: $hover-color;
    background-color: white;
  }
}

.burger-menu {
  padding: 3px;
  width: 30px;
  height: 30px;
  fill: $white-text-color;
  &:hover {
    fill: $hover-color;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.logo-container {
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.home-final-page {
  color: $text-color;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-top: 150px;
}

.massages-list {
  padding-top: 20px;
}

.front-picture {
  border-radius: 50%;

  @media screen and (max-width: 767.8px) {
    width: 70px;
  }
  @media screen and (min-width: 768px) {
    width: 100px;
  }
  @media screen and (min-width: 1024px) {
    width: 100px;
  }
  @media screen and (min-width: 1440px) {
    width: 200px;
  }
  width: 200px;
  display: block;
  border: white solid 5px;
  margin-left: 10px;
  background-color: white;
}

.massage-list-item {
  @media screen and (max-width: 767.8px) {
    margin-top: 10px;
    font-size: 12px;
  }
  @media screen and (min-width: 1024px) {
    width: calc(100%-10px);
  }
  @media screen and (min-width: 1440px) {
    width: 900px;
  }
  color: white;
  font-size: 16px;
  text-transform: uppercase;

  background: rgb(203, 172, 146);
  justify-content: space-between;
  border-bottom-right-radius: 200px;
  border-top-right-radius: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 20px;
}

.item-container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
}
